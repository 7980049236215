var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name')}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.date')}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.file_date),callback:function ($$v) {_vm.$set(_vm.form_data, "file_date", $$v)},expression:"form_data.file_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"upload files","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"files"}},[_vm._v(_vm._s(_vm.$t("g.uploadFiles")))]),_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.placeholder.chooseAFileOrDropItHere'),"drop-placeholder":_vm.$t('g.dropFileHere'),"state":errors.length > 0 ? false : null,"multiple":""},model:{value:(_vm.form_data.file),callback:function ($$v) {_vm.$set(_vm.form_data, "file", $$v)},expression:"form_data.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"files"}},[_vm._v(_vm._s(_vm.$t("g.description")))]),_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('g.placeholder.enterTheDescriptionHere'),"state":errors.length > 0 ? false : null,"rows":"4"},model:{value:(_vm.form_data.description),callback:function ($$v) {_vm.$set(_vm.form_data, "description", $$v)},expression:"form_data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }